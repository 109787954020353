import React from "react";
import "./Footer.scss";
import copyright from "../../images/copyright.svg";

function Footer() {
  return (
    <div id="footerDiv">
      <div className="infoText">
        <div id="contactHeader">Building solutions with impact.</div>
        <div className="footerInfo">
          <div className="officeInfo-container">
            <div id="officeHeader">Office</div>
            <div id="officeAddress">
              2318 South Union Ave. Los Angeles, CA 90007
            </div>
          </div>
          <div className="officeInfo-container">
            <div id="phoneNumHeader">Phone Number</div>
            <div id="phoneNumber">+1 (562) 348-5765</div>
          </div>
          <div className="officeInfo-container">
            <div id="emailHeader">Email</div>
            <div id="emailInfo">info@urbantxt.com</div>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <div className="image-copy-container">
          <img id="copyrightIcon" src={copyright} alt="copyright icon" />
          <p id="copyrightText">2025 TXT LABS ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
